import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/notification.css";
import { sendsendNotificationsApi } from "../Api/api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import options from "../Data/loader";
import SelectLanguage from "./1SelectLanguage";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: tEn },
    ps: { translation: tPs },
    dr: { translation: tDr },
  },
  // lng:"ps",
  // fallbackLng: "en",

  // interpolation: {
  //   escapeValue: false
  // }
});

let token;
const Notification = () => {

  //To Change Language
  const { t } = useTranslation();

  //To get language from local Storage
  const handleChangeLang = () => {
    let language = localStorage.getItem("language");
    i18n.changeLanguage(language);
  };

  //To load language on page load
  useEffect(() => {
    handleChangeLang();
  }, []);

  //To Go on any other page
  const navigate = useNavigate();

  //For Loader
  let Loader = require('react-loader');

  //Hook to store loader state
  const[loaderState,setLoaderState]=useState(false);

  //To call players data
  useEffect(() => {
    getToken();
    getNotificationData();
    // eslint-disable-next-line
  }, []);

  //Hooks to store Notifications
  const [commonNotification, setCommonNotification] = useState([]);
  const [specialNotification, setSpecialNotification] = useState([]);

  //Calling Players Data
  const getNotificationData = () => {
    
    let ani = localStorage.getItem("ani");
    let request = { ani: ani };
    //console.log("request is ", request);

    let token=localStorage.getItem("token");
    let headers={"Authorization":"Bearer "+token};

    axios.post(`${sendsendNotificationsApi}`, request,{headers:headers}).then(
      (response) => {
        //console.log(response.data);
        setCommonNotification(response.data.commonNotifications);
        setSpecialNotification(response.data.specialNotifications);

        setLoaderState(true); //Disabling Loader

        if(response.data.commonNotifications.length===0
            && response.data.specialNotifications.length===0)
        {
            toast.success("No New Notification Yet");
        }
      },
      (error) => {
        //console.log(error);
        if(error.response.status===403)
        {
          //console.log("Token Expired");
          toast.error("Token Expired Plz Login again !!");
          navigate("/");
        }
        else
        {
          toast.error("Something Went Wrong");
        }
      }
    );
  };

  const sendToHome = () => {
    navigate("/home");
  };

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

    if(token===undefined||token===null||token===''||token===' ')
    {
    return(
        <>
          <SelectLanguage/>
        </>
      );
    }
    else
    {
      return (
        <div>
          <ToastContainer />
          <section className="notification-main full-height">
            <div className="topplayer-main-2 flex-grow-1">
              <div className="rewards-navbar pb-20">
                <div className="rewards-nav-1">
                  <button 
                    onClick={() => {
                      sendToHome();
                    }}>
                    <span>
                      <i className="fa-solid fa-arrow-left"></i>
                    </span>
                  </button>
                  <div className="reward_title">
                    <span className="rewards-para-1">
                      <button><h5>{t("Notifications")}</h5></button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="custabs">
                <Tabs
                  defaultActiveKey="home"
                  transition={true}
                  id="noanim-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="home" title={t("Common Notifications")}>
                    {/* <div className="topplayer-ctgry1">
                      <div className="topplayer-btn-2">{t("Common Notifications")}</div>
                    </div> */}
                    <ul>
                    {commonNotification.length > 0 &&
                      commonNotification.map((value, index) => {
                        // //console.log("index ",index);
                        // //console.log("value ",value);
    
                        return (
                          <li
                            className="notifi_item"
                            key={index}
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="notifi-data">
                              <div className="sr-no">{index + 1}</div>
                              {/* <div className="topplayer-img">
                                <img alt="ImgSrc" src={value.image} />
                              </div> */}
                              <div className="notfi_dtl">
                                <h5>{value.notification}</h5>
                                <p>{value.type}</p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </Tab>
                  <Tab eventKey="profile" title={t("Special Notifications")}>
                    {/* <div className="topplayer-ctgry1">
                      <div className="topplayer-btn-2">{t("Special Notifications")}</div>
                    </div> */}
    
                    <ul>
                    {specialNotification.length > 0 &&
                      specialNotification.map((value, index) => {
                        // //console.log("index ",index);
                        // //console.log("value ",value);
    
                        return (
                          <li
                            className="notifi_item"
                            key={index}
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="notifi-data">
                              <div className="sr-no">{index + 1}</div>
                              {/* <div className="topplayer-img">
                                  <img alt="ImgSrc" src={value.image} />
                                </div> */}
                              <div className="notfi_dtl">
                                <h5>{value.notification}</h5>
                                <p>{value.type}</p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <Footer />
          </section>
          <Loader loaded={loaderState} options={options} className="spinner" />
        </div>
      );
    }
};
export default Notification;