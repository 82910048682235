import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "../Css/showquiz.css";
import { sendQuestionLogs } from "../Api/api";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import options from "../Data/loader";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const ShowQuiz = () => {
  const { t } = useTranslation();

  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

useEffect(()=>{
  handleChangeLang();
},[]);

  //to go on any other page
  const navigate=useNavigate();

  //For Loader
  let Loader = require('react-loader');

  //Hook to store loader state
  const[loaderState,setLoaderState]=useState(false);

  //hook to call method on first load 1 time
  useEffect(() => {
    getToken();
    getLocalStorageData();
    // eslint-disable-next-line
  }, []);

  //Method to get Data from Local Storage of Browser
  const getLocalStorageData = () => {
    let ani = localStorage.getItem("ani");
    let category = localStorage.getItem("category");
    let service = localStorage.getItem("service");
    let language = localStorage.getItem("language");

    getQuestionLogsData(ani, category, service, language);
  };

  const [questions, setQuestions] = useState([]);

  //Method to get Question Logs from Backend
  const getQuestionLogsData = (ani, category, service, language) => {
    let request = {
      ani: ani,
      category: category,
      service: service,
      language: language,
    };

    let token=localStorage.getItem("token");
    let headers={"Authorization":"Bearer "+token};

    axios.post(`${sendQuestionLogs}`, request,{headers:headers}).then(
      (response) => {
        //console.log(response.data.response);
        setQuestions(response.data.response);
        setLoaderState(true);

        if(response.data.response.length===0)
        {
          toast.success("No Content To Show");
        }
      },
      (error) => {
        //console.log(error);
        if(error.response.status===403)
        {
          //console.log("Token Expired");
          toast.error("Token Expired Plz Login again !!");
          navigate("/");
        }
        else
        {
          toast.error("Something Went Wrong");
        }
      }
    );
  };

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

    if(token===undefined||token===null||token===''||token===' ')
    {
    return(
        <>
          <SelectLanguage/>
        </>
      );
    }
    else
    {
      return (
        <>
          <ToastContainer />
          <section className="showquiz-main full-height">
            <div className="showquiz-main-2 flex-grow-1">
              <div className="rewards-navbar ">
                <div className="rewards-nav-1">
                  <button onClick={()=>{
                    navigate("/score");
                  }}>
                    <span>
                      <i className="fa-solid fa-arrow-left"></i>
                    </span>
                  </button>
                  <div className="reward_title">
                    <span className="rewards-para-1">
                      <button><h5>{t('Questions')}</h5></button>
                    </span>
                  </div>
                </div>
              </div>
              
              <div className="showquiz-all-ques-ans">
                {questions.length > 0 &&
                  questions.map((value, index) => {
                    // //console.log("index ", index);
                    // //console.log("value ", value);
    
                    return (
                      <div className="showquiz-data" key={index}>
                        {/* <div className="showquiz-img">
                          <img alt="ImgSrc" src="https://www.gameninja.in/html/Azmoon/avatar.png" />
                        </div> */}
                        <div className="srno">
                          <p className="mb-0">{index+1}.</p>
                        </div>
                        <div className="showquiz-ques-ans">
                          <div className="showquiz-ques-1">
                            <h5>
                              {value.question}
                            </h5>
                          </div>
                          <div className="showquiz-ans-1">
                            <span className="showquiz-answer-1">{t('Answer')} : </span>{" "}
                            <span className="showquiz-pa">{value.correctAnswer}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
    
                {/* <div className="showquiz-data">
                  <div className="showquiz-img">
                    <img alt="ImgSrc" src={profile_image} />
                  </div>
                  <div className="showquiz-ques-ans">
                    <div className="showquiz-ques-1">
                      <h5>
                        1. Who owned the tully family the fish symbol in Winterfall
                      </h5>
                    </div>
                    <div className="showquiz-ans-1">
                      <span className="showquiz-answer-1">Answer : </span>{" "}
                      <span className="showquiz-pa">Katain Stark</span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="showquiz-data">
                  <div className="showquiz-img">
                    <img alt="ImgSrc" src={profile_image} />
                  </div>
                  <div className="showquiz-ques-ans">
                    <div className="showquiz-ques-1">
                      <h5>
                        1. Who owned the tully family the fish symbol in Winterfall
                      </h5>
                    </div>
                    <div className="showquiz-ans-1">
                      <span className="showquiz-answer-1">Answer : </span>{" "}
                      <span className="showquiz-pa">Katain Stark</span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="showquiz-data">
                  <div className="showquiz-img">
                    <img alt="ImgSrc" src={profile_image} />
                  </div>
                  <div className="showquiz-ques-ans">
                    <div className="showquiz-ques-1">
                      <h5>
                        1. Who owned the tully family the fish symbol in Winterfall
                      </h5>
                    </div>
                    <div className="showquiz-ans-1">
                      <span className="showquiz-answer-1">Answer : </span>{" "}
                      <span className="showquiz-pa">Katain Stark</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <Footer />
          </section>
          <Loader loaded={loaderState} options={options} className="spinner" />
        </>
      ); 
    }
};
export default ShowQuiz;