import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import azmoon_logo from "../Images/Azmoon logo.png";
import "../Css/selectlanguage.css";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      lng:"en", 
      fallbackLng: "en",
  
      interpolation: {
        escapeValue: false 
      }
    });

const SelectLanguage=()=>{

    //To Translate Language
    const { t } = useTranslation();

    //To go on other pages
    const navigate=useNavigate();

    //Getting Language
    const languageHandler=(lang)=>{
        // language=lang;
        //console.log("Choosed Language is ",lang);
        i18n.changeLanguage(lang);
        localStorage.setItem("language",lang);
    }
  
    //If no language select then select default lang i.e english
    const handleContinue=()=>{
      let language=localStorage.getItem("language");
      if(language===null || language===undefined)
      {
        localStorage.setItem("language","en");
      }

      let token=localStorage.getItem("token");
      //console.log("token ",token);
      if(token===undefined || token===null || token==='' || token===' ' || token==='null')
      {
        //console.log("Inside Login");
        navigate("/login");
      }
      else
      {
        //console.log("Inside Home");
        navigate("/home");
      }
    }

    //To Load Language
    useEffect(()=>{
      handleChangeLang();
    },[]);

    //To Get Language from Local Storage
    const handleChangeLang=()=>{
      let language=localStorage.getItem("language");
      i18n.changeLanguage(language);
  }

  return (
    <>
      <section className="selectlanguage-main">
        <div className="selectlanguage-main-2">
          <div className="selectlanguage-quiz-logo">
            <img alt="ImgSrc" src={azmoon_logo} />
          </div>

          <div className="selectlanguage-text-1 pt-3">
            <div className="selectlanguage-para-1">
              <p className="mb-2 text-center">{t('Select your preferred language')}</p>
            </div>
          </div>
          <div className="selectlanguage-radio mb-3">
            <form>
              <div className="cusRadio">
                <input type="radio" id="html" name="fav_language" value="HTML"
                onChange={()=>{
                  languageHandler('en');
                }} />
                <label htmlFor="html">English</label>
              </div>
              
              <div className="cusRadio">
                <input type="radio" id="css" name="fav_language" value="CSS" 
                onChange={()=>{
                  languageHandler('ps');
                }}/>
                <label htmlFor="css">پشتو</label>
              </div>

              <div className="cusRadio">
                <input
                  type="radio"
                  id="javascript"
                  name="fav_language"
                  value="JavaScript"
                  onChange={()=>{
                      languageHandler('dr');
                  }}
                />
                <label htmlFor="javascript">دری</label>
              </div>
            </form>
          </div>

          <div className="selectlanguage-second-prt">
            <div className="selectlanguage-anchors">
              <div className="selectlanguage-ancr-1">
                
                  <button onClick={()=>{
                    handleContinue();
                  }} style={{color:"white"}}>{t('Continue')}</button>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SelectLanguage;