import axios from "axios";

const PostRequest=async(url,request)=>{

    let sendResponse;
    let token=localStorage.getItem("token");
    let headers={"Authorization":"Bearer "+token};
    
    await axios.post(url,request,{headers:headers})
    .then(
        (response)=>{
            // console.log(response.data);
            sendResponse=response.data;
        },
        (error)=>{
            // console.log(error);
            let map={"response":"5"};
            sendResponse=map;
            
            if(error.response.status===403)
            {
                let map={"response":"6"};
                 sendResponse=map;
            }
        }
    )
    return sendResponse;
}
export default PostRequest;