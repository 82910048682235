import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import "../Css/setting.css";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import SelectLanguage from "./1SelectLanguage";
import { unsubRequestApi } from "../Api/api";
import PostRequest from "../Api/PostRequest";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const Setting = () => {

//To Translate Language
const { t } = useTranslation();

//To Get selected language from local storage
  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

//to load language
useEffect(()=>{
  handleChangeLang();
},[]);

useEffect(()=>{
  getToken();
},[]);

//To go on other page
  const navigate=useNavigate();

//to hide and show language buttons
const selectingLanguage=()=>{
  let list = document.querySelector('.settingpage-list');
  list.classList.toggle('settingpage-newlist');
}

//to change to selected language
const choosedNewLanguage=(lang)=>{
  //console.log("choosed language is ",lang);
  localStorage.setItem("language",lang);
  i18n.changeLanguage(lang);

  let list = document.querySelector('.settingpage-list');
  list.classList.toggle('settingpage-newlist');
}

//Method to Handle Unsub Button
const unsubMethod=()=>{
  //console.log("Method to Handle Unsub");
  settingState(true);
  handleUnsub();  
}

const handleUnsub=()=>{
  //Send msisdn to backend for unsubscription
  let ani=localStorage.getItem("ani");
  let lang=localStorage.getItem("language");

  let request={"ani":ani,"lang":lang};
  let promise=PostRequest(unsubRequestApi,request);
  promise.then(e=>{
    handleResponse(e);
  })
}

const[buttonState,setButtonState]=useState(false);
const[spanState,setSpanState]=useState(false);
const[loaderState,setLoaderState]=useState(false);

//Method to Handling Backend Response
const handleResponse=(e)=>{
  settingState(false);
  //console.log("e ",e);

  //Removing from local storage
  localStorage.removeItem("token");
  localStorage.clear();

  navigate("/");
}

//Method to setting States of button & Loader
  const settingState=(value)=>{
    setButtonState(value);
    setLoaderState(value);
    setSpanState(value);
  }

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

    if(token===undefined||token===null||token===''||token===' ')
    {
    return(
        <>
          <SelectLanguage/>
        </>
      );
    }
    else
    {
      return (
        <div>
          <section className="settingpage-main full-height">
            <div className="settingpage-main-2 flex-grow-1">
              <div className="rewards-navbar ">
                <div className="rewards-nav-1">
                  <button onClick={()=>{
                      navigate("/home");
                    }}>
                    <span>
                      <i className="fa-solid fa-arrow-left"></i>
                    </span>
                  </button>
                  <div className="reward_title">
                    <span className="rewards-para-1">
                      <button><h5>{t('Setting')}</h5></button>
                    </span>
                  </div>
                </div>
              </div>
    
              <div className="settingpage-second-sec">
                <button className="settingpage-select-button"
                  onClick={()=>{
                  }}>
                  <h5>{t('Select your preferred language')}</h5>
                </button>
    
                <div className="settingpage-container">
                  <button className="settingpage-click" onClick={()=>{
                    selectingLanguage();
                  }}>
                    {" "}
                    <span className="settingpage-lang-1">{t('Select the language')}</span>
                    <span className="settingpage-icon-2">
                      <i className="fa-solid fa-caret-down"></i>
                    </span>{" "}
                  </button>
    
                  <div className="settingpage-list" id="language-option-div">
                    <button className="settingpage-links link-1" onClick={()=>{
                      choosedNewLanguage('en');
                    }}>
                      {" "}
                      <span className="settingpage-rel-lang-1">English</span>
                      <span className="settingpage-icon-3">
                        <i className="fa-regular fa-circle-dot"></i>
                      </span>
                    </button>
                    <button className="settingpage-links link-2" onClick={()=>{
                      choosedNewLanguage("dr");
                    }}>
                      {" "}
                      <span className="settingpage-rel-lang-1">Persian</span>
                      <span className="settingpage-icon-3">
                        <i className="fa-regular fa-circle-dot"></i>
                      </span>
                    </button>
                    <button className="settingpage-links link-3" onClick={()=>{
                      choosedNewLanguage("ps");
                    }}>
                      {" "}
                      <span className="settingpage-rel-lang-1">Pashto</span>
                      <span className="settingpage-icon-3">
                        <i className="fa-regular fa-circle-dot"></i>
                      </span>
                    </button>
                  </div>
                </div>
    
                <div className="saveBtn pt-2">
                  <button className="btn btn-orange"
                    disabled={buttonState}
                    onClick={()=>{
                      unsubMethod();
                    }}>
                      <span hidden={spanState}>{t("Click Here to Unsubscribe")}</span>
                      <SyncLoader color="orange" size="15" className="login-button-loader" loading={loaderState}/>
                      </button>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </div>
      );
    }
};
export default Setting;