import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/topplayer.css"
import { sendTopPlayers } from "../Api/api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const TopPlayers = () => {

  //To Translate Language
  const { t } = useTranslation();

  //To Get User Selected Language from Local Storage
  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

//Load User selected Language on Page Load
useEffect(()=>{
  handleChangeLang();
},[]);

//To Navigate on any other page
const navigate=useNavigate();

//To call players data 
useEffect(()=>{
    getToken();
    getPlayersData();
    // eslint-disable-next-line
},[]);

//hook to store player data
const[players,setPlayers]=useState([]);

//Calling Players Data
const getPlayersData=()=>{
  let token=localStorage.getItem("token");
  let headers={"Authorization":"Bearer "+token};

    axios.get(`${sendTopPlayers}`,{headers:headers})
    .then(
        (response)=>{
            //console.log(response.data);
            setPlayers(response.data.players);
        },
        (error)=>{
          //console.log(error);
          if(error.response.status===403)
          {
            //console.log("Token Expired");
            toast.error("Token Expired Plz Login again !!");
            navigate("/");
          }
          else
          {
            toast.error("Something Went Wrong");
          }
        }
    )
}

//To go on home page
const sendToHome=()=>{
    navigate("/home");
}

//Method to Get & Check Token
const getToken=()=>{
  token=localStorage.getItem("token");
}

  if(token===undefined||token===null||token===''||token===' ')
  {
  return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <>
       <ToastContainer/>
        <section className="topplayer-main full-height">
          <div className="topplayer-main-2 flex-grow-1">
            <div className="rewards-navbar ">
              <div className="rewards-nav-1">
                <button onClick={()=>{
                  sendToHome();
                }}>
                  <span>
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                </button>
                <div className="reward_title">
                  <span className="rewards-para-1">
                    <button><h5>{t('Top Players')}</h5></button>
                  </span>
                </div>
              </div>
            </div>
            <div className="game_leader">
              <div className="ctgry">
                <button className="active">{t('All')}</button>
                <button className="">{t('Daily')}</button>
                <button className="">{t('Weekly')}</button>
                <button className="">{t('Monthly')}</button>
              </div>
              {
                  players.length>0 && players.map((value,index)=>{
                      // //console.log("index ",index);
                      // //console.log("value ",value);
  
                      return(
                          <div className="topplayer-top-player" key={index} style={{marginBottom:"20px"}}>
                            <div className="topplayer-player-data">
                              
                              <div className="topplayer-sr-no">{index+1}</div>
                                <div className="topplayer-img">
                                  <img alt="ImgSrc" src={value.image} />
                                </div>
                                <div className="topplayer-nm">
                                  <h5 className="mb-0">{value.name}</h5>
                                  <p className="mb-0">{value.score} {t('score')}</p>
                                </div>
                              </div>
              
                         
                          {index===0?
                              <>
                              <div className="topplayer-gift">
                              <h5 className="mb-0">{t('1 GB Data bundle')}</h5>
                              <i className="fa-solid fa-gift"></i>
                              </div>
                              {/* <div className="topplayer-positions">
                              <h5>Your position : 71</h5>
                              </div> */}
                              </>:<></>
                          }
                          
                        </div>
                      );
                  })
                  }
            </div>
  
          </div>
          <Footer />
        </section>
      </>
    );
  }
};
export default TopPlayers;