import React, { useEffect, useState } from "react";
import { ToastContainer,toast } from "react-toastify";
import {SyncLoader} from "react-spinners";
import { useNavigate } from "react-router-dom";
import logo from "../Images/Azmoon logo.png";
import caldendar_img from "../Images/calendar.png";
import { subscribeUserApi } from "../Api/api";
import "../Css/subscribe.css";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
// import PostRequest from "../Api/PostRequest";
import PostRequestNormal from "../Api/PostRequestNormal";
import auto from "../Images/auto.png";
import one from "../Images/one.png";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: tEn },
    ps: { translation: tPs },
    dr: { translation: tDr },
  },
  // lng:"en",
  // fallbackLng: "en",

  // interpolation: {
  //   escapeValue: false
  // }
});

const Subscribe = () => {
  //To Translate Language
  const { t } = useTranslation();

  //To go on other page
  const navigate = useNavigate();

  //To Get Language from Local Storage
  const handleChangeLang = () => {
    let language = localStorage.getItem("language");
    i18n.changeLanguage(language);
  };

  //To Load Language on Page Load
  useEffect(() => {
    handleChangeLang();
  }, []);

  //State to hold pack
  const [pack,setPack] = useState({});
  const[billingType,setBillingType]=useState({});

  //Hook to store loader etc states
  const[states,setStates]=useState({loaderState:false,disableState:false,visibleState:false});

  //when click on confirm button
  const handleConfirm = () => {

    console.log("pack is ", pack);
    if(pack.type===undefined)
    {
      toast.error("Choose Pack First");
    }
    if(billingType.type===undefined)
    {
      toast.error("Choose Billing Type First");
    }
    else
    {
      //Handling States
      setStates({loaderState:true,disableState:true,visibleState:true});

      //Setting pack in local storage
      localStorage.setItem("packType", pack.type);

      //Send Request for Subscription
      let lang=localStorage.getItem("language");
      let request={"ani":localStorage.getItem('ani'),"pack":pack.type,"lang":lang,"billingType":billingType.type};
      console.log("request is ",request);

      let promise=PostRequestNormal(subscribeUserApi,request);
      promise.then(e=>{
        console.log("e ",e);
          handleResponse(e);
      })

    }
  };

  const handleResponse=(e)=>{
    setStates({loaderState:false,disableState:false,visibleState:false});

    if(e.response==='5')
    {
      navigate("/error");
    }
    else if(e.response==='0')
    {
      //console.log("Billing Fail");
      toast.error("Billing Fail, Try again Later!!");
    }
    else if(e.response==='1')
    {
      //console.log("Billing Success");
      toast.success("Billing Successful, Please Confirm OTP");

      setTimeout(()=>{
        navigate("/otp");
      },1000);
    }
  }

  return (
    <div>
      <ToastContainer/>
      <section className="selectlanguage-main subscribe-main">
        <div className="selectlanguage-main-2 subscribe-main-2">
          <div className="subscribe-quiz-logo">
            <img alt="ImgSrc" src={logo} />
          </div>
          <div className="subscribe-text-1">
            <div className="subscribe-para-1 pt-3">
              <h5 className="fw-bold text-center fs-6 mb-4">
                {t("Select the subscription package you want")}
              </h5>
            </div>
          </div>
          <div className="subscribe-wrapper">
            <button
              className="w-100 subscribe-choose-button"
              onClick={() => {
                setPack({ ...pack, type: "Daily" });
              }}
            >
              <div className="subscribe-option">
                <input type="radio" id="check1" name="radio" />
                <label htmlFor="check1">
                  <img alt="ImgSrc" src={caldendar_img} />
                  <span>{t("Daily")}</span>
                  <p>4 AFN</p>
                </label>
              </div>
            </button>

            <button
              className="w-100 subscribe-choose-button"
              onClick={() => {
                setPack({ ...pack, type: "Weekly" });
              }}
            >
              <div className="subscribe-option">
                <input type="radio" id="check2" name="radio" />
                <label htmlFor="check2">
                  <img alt="ImgSrc" src={caldendar_img} />
                  <span>{t("Weekly")}</span>
                  <p>15 AFN</p>
                </label>
              </div>
            </button>

            <button
              className="w-100 subscribe-choose-button"
              onClick={() => {
                setPack({ ...pack, type: "Monthly" });
              }}
            >
              <div className="subscribe-option">
                <input type="radio" id="check3" name="radio" />
                <label htmlFor="check3">
                  <img alt="ImgSrc" src={caldendar_img} />
                  <span>{t("Monthly")}</span>
                  <p>30 AFN</p>
                </label>
              </div>
            </button>
            
            <hr/>
            <div>

                <div className="subscribe-text-1">
                <div className="subscribe-para-1 pt-3">
                  <h5 className="fw-bold text-center fs-6 mb-4">
                    {t("Select Billing Type")}
                  </h5>
                </div>
               </div>

              {/* <button
                className="w-100 subscribe-choose-button"
                onClick={() => {
                  setBillingType({ ...billingType, type: "one" });
                }}
              >
                <div className="subscribe-option">
                  <input type="radio" id="check4" name="four" />
                  <label htmlFor="check4">
                    <img alt="ImgSrc" src={one} />
                    <span>{t("One Time Subscription")}</span>
                  </label>
                </div>
              </button> */}
               {/* One Time Subscription */}
    <button
      className="w-100 subscribe-choose-button"
      onClick={() => {
        setBillingType({ ...billingType, type: "one" });
      }}
    >
      <div className="subscribe-option">
        <input type="radio" id="check4" name="billingType" />
        <label htmlFor="check4">
          One Time Subscription
        </label>
      </div>
    </button>

              {/* <button
              className="w-100 subscribe-choose-button"
              onClick={() => {
                setBillingType({ ...billingType, type: "auto" });
              }}
            >
              <div className="subscribe-option">
                <input type="radio" id="check5" name="radio" />
                <label htmlFor="check5">
                  <img alt="ImgSrc" src={auto} />
                  <span>{t("Auto Renewal")}</span>
                </label>
              </div>
            </button> */}
            {/* Auto Renewal */}
    <button
      className="w-100 subscribe-choose-button"
      onClick={() => {
        setBillingType({ ...billingType, type: "auto" });
      }}
    >
      <div className="subscribe-option">
        <input type="radio" id="check5" name="billingType" />
        <label htmlFor="check5">
          Auto Renwals
        </label>
      </div>
    </button>
            </div>

          </div>
          <div className="subscribe-anchor">
            <button
              disabled={states.disableState}
              onClick={() => {
                handleConfirm();
              }}
            >
              <span hidden={states.visibleState}>{t("Confirm")}</span>
              <SyncLoader color="white" size="15" className="login-button-loader" loading={states.loaderState}/>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Subscribe;