import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/seeall.css";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Sidebar } from "./Sidebar";
import { Footer } from "./Footer";
import { NavbarTop } from "./NavbarTop";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"en", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const SeeAll = () => {

  //To Translate Language
  const { t } = useTranslation();

  //To Get Language from Local Storage
  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

//To Load Language on Page Load
useEffect(()=>{
  handleChangeLang();
},[]);

//To go on other pages
  const navigate=useNavigate();

  //hook to call on first load
  useEffect(() => {
    getLocalStorageData();
    getToken();
    // eslint-disable-next-line
  }, []);

  //Hook to store categories data
  const [data, setData] = useState([]);

  //Hook to store quiz category
  const[quizCategory,setQuizCategory]=useState('');

  //Hook to store user Data
  const[userData,setUserData]=useState({});

  //Hook to store top topic data
  const[topTopic,setTopTopic]=useState([]);

  //Getting Data from Local Storage
  const getLocalStorageData = () => {
    let seeAllCategory = localStorage.getItem("seeAllCategory");
    let user = JSON.parse(localStorage.getItem("user"));
    let topTopic = JSON.parse(localStorage.getItem("topTopic"));
    let latestTopic = JSON.parse(localStorage.getItem("latestTopic"));

    //console.log("seeAllCategory is ", seeAllCategory);
    //console.log("user ", user);
    //console.log("topTopic ", topTopic);
    //console.log("latestTopic ", latestTopic);

    setQuizCategory(seeAllCategory.toUpperCase());
    setUserData(user);
    setTopTopic(topTopic);

    gettingData(seeAllCategory, user, topTopic, latestTopic);
  };

  //getting data according to choosed one
  const gettingData = (seeAllCategory, user, topTopic, latestTopic) => {
    //console.log("Getting Data");
    //console.log("category is ", seeAllCategory);
    if (seeAllCategory === "topTopic") {
      //Setting topTopic Data
      //console.log("Inside topTopic");
      //console.log("topTopic Data is ", topTopic);
      setData(topTopic);
    } else if (seeAllCategory === "latestTopic") {
      //Setting latestTopic Data
      //console.log("Inside latestTopic");
      //console.log("latestTopic Data is ", latestTopic);
      setData(latestTopic);
    } else if (seeAllCategory === "favouriteTopic") {
      //Setting favouriteTopic Data
      //console.log("Inside favouriteTopic");
      //console.log("favouriteTopic Data is ", user.favourite);
      setData(user.favourite);
    }
  };

  useEffect(() => {
    // //console.log("data is : ",data);
    if (data.length > 0) {
      gettingChoosedOne();
    }
    // eslint-disable-next-line
  }, [data]);

  const gettingChoosedOne = () => {
    //console.log("Choosed Data is ", data);
  };

  //To handle click on selected game
  const handleSelectGame=(id)=>{
    //console.log("id is ",id);
    data.forEach(e=>{
        if(id===e.id)
        {
            //console.log("selected cat is ",e);
            localStorage.setItem("game",JSON.stringify(e));
            setCategoryAndService(e.name,e.service);
            navigate("/game");    
        }
    })
  }

  const setCategoryAndService=(cat,ser)=>{
    localStorage.setItem("category",cat);
    localStorage.setItem("service",ser);
  }

  //Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===undefined||token===null||token===''||token===' ')
  {
    return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <>
        <Sidebar userName={userData.name} userImage={userData.image} userAni={userData.ani} />
        <div className="home-main full-height">
          <NavbarTop />
          <div className="flex-grow-1 px-20 py-3">
            <section className="seeall-main">
              <div className="seeall-main-2">
  
                <div className="seeall-hm-top">
                  <div className="mb-0 row align-items-center">
                    <h4 className="col-6 mb-0"><span id="topics">{t(quizCategory)}</span></h4>
                    <div className="cusbreadcrumb col-6 text-end">
                      <span onClick={()=>{navigate("/home");}}
                         style={{color:"blue",cursor:"pointer"}}>{t('Home')} &gt;</span>
                      <span id="topics">{t(quizCategory)}</span>
                    </div>
                  </div>
                </div>
  
                <div className="seeall-all-catg">
                  <div className="row">
                    {data.length > 0 &&
                      data.map((value, index) => {
                        return (
                          <div className="col-md-6 col-lg-4 my-3" key={index}>
                            <div className="home-category" key={index} onClick={()=>{
                                handleSelectGame(value.id);
                            }}>
                              <div className="home-cate-img-1">
                                <img alt="ImgSrc" src={value.thumbnail} className="img-fluid" />
                              </div>
                              <div className="home-cate-cnt-1">
                                <h5>{t(value.name)}</h5>
                                <p>
                                  {t(value.description)} / {value.plays} {t('plays')}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer data={topTopic}/>
        </div>
      </>
    );
  }
};
export default SeeAll;