import React, { useEffect,useState } from "react";
// import azmoon_logo from "../Images/Azmoon logo.png";
import { useNavigate } from "react-router-dom";
import "../Css/update.css";
import { updateImage,sendUser } from "../Api/api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import uploaded_img from "../Images/GOT.jpg";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const Update = () => {
  const { t } = useTranslation();

  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

useEffect(()=>{
  handleChangeLang();
},[]);

    //Load on first time
    useEffect(()=>{
        getToken();
        getLocalStorageData();
    },[]);

    //State to hold user Data
    const[user,setUser]=useState({});

    const[path,setPath]=useState(null);

    const[ani,setAni]=useState('');

    // eslint-disable-next-line
    const[name,setName]=useState('');

    //Get data from Local Storage
    const getLocalStorageData=()=>{
        let user=JSON.parse(localStorage.getItem("user"));
        setUser(user);

        let ani=localStorage.getItem("ani");
        //console.log("ani is ",ani);

        //console.log("Before");
        setAni(ani);
        //console.log("After");
    }

    const handleUpdateButton=()=>{
        //console.log("name is ",name);
        //console.log("path is ",path);   
        hitOnBackend(path);
    }

    const hitOnBackend=(path)=>{

        // let request={"ani":ani,"image":path};

        let formData=new FormData();
        // formData.set("ani",ani);
        formData.append("ani",ani);
        formData.append("image",path);

        formData.forEach(e=>{
            //console.log("e ",e);
        })

        let token=localStorage.getItem("token");
        let headers={"Authorization":"Bearer "+token,"Content-Type":"multipart/form-data"};

        axios({
            method:"post",
            url:`${updateImage}`,
            data:formData,
            headers:headers,
        })
        .then(
            (response)=>{
                //console.log(response.data);
                toast.success("Updated Successfully");
                getUserData();
            },
            (error)=>{
                //console.log(error);
                toast.error("File size is too Large, Keep Under 2 MB");
            }
        )
    }
  const navigate = useNavigate();

    //Call User to update Image in application
    //Method to get Logged in user data
  const getUserData = () => {
    // eslint-disable-next-line
    let ani=localStorage.getItem("ani");
    let request = { "ani": ani };

    let token=localStorage.getItem("token");
    let headers={"Authorization":"Bearer "+token};

    axios.post(`${sendUser}`, request,{headers:headers})
      .then(
        (response) => {
          //console.log(response.data);
          //Adding User to LocalStorage
          localStorage.setItem("user", JSON.stringify(response.data.user));
        },
        (error) => {
          //console.log(error);
          if(error.response.status===403)
          {
            //console.log("Token Expired");
            toast.error("Token Expired Plz Login again !!");
            navigate("/");
          }
          else
          {
            toast.error("Something Went Wrong");
          }
        }
      )
  }

  const handleSelectImage=(e)=>{
    setPath(e.target.files[0]);
    //console.log("path is ",path);

    let imgPreview=document.getElementById("showImage");
    if(path)
    {
      const fileReader=new FileReader();
      fileReader.onload=(e)=>{
        imgPreview.src=e.target.result;
      }
      fileReader.readAsDataURL(e.target.files[0]);
    }
  }

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

    if(token===undefined||token===null||token===''||token===' ')
    {
    return(
        <>
          <SelectLanguage/>
        </>
      );
    }
    else
    {
      return (
        <div>
         <ToastContainer/>
          <section className="update-main full-height">
            <div className="update-main-2 flex-grow-1">
              <div className="rewards-navbar pb-20">
                <div className="rewards-nav-1">
                  <button onClick={() => {
                      navigate("/home");
                    }}>
                    <span>
                      <i className="fa-solid fa-arrow-left"></i>
                    </span>
                  </button>
                  <div className="reward_title">
                    <span className="rewards-para-1">
                      <button><h5>{t('Update Profile')}</h5></button>
                    </span>
                  </div>
                </div>
              </div>
            
              {/* <div className="update-quiz-logo">
                <img alt="ImgSrc" src={azmoon_logo} />
              </div> */}
    
              <div className="update_profile">
                <div className="update-name-1 mb-3">
                  <label>Enter your name</label>
                  <input type="text" id="name" placeholder="Name" value={user.name}
                    onChange={(e)=>{
                      setName(e.target.value);
                    }} className="form-control"/>
                </div>
                <div className="update-inputs-1">
                  <label>Update Profile image</label>
                  <input type="file" id="upload" hidden onChange={(e)=>{
                      handleSelectImage(e);
                  }}/>
                  <label htmlFor="upload" className="update-label-class">{t('Choose Photo')}</label>
                  <div className="uploaded_img_box">
                    <div className="uploaded_inner">
                      <img src={uploaded_img} alt="profile" className="img-fluid" id="showImage"/>
                      <span><i className="fa fa-times"></i></span>
                    </div>
                  </div>
                </div>
              
                <div className="update-submit-btn">
                  <button onClick={()=>{
                      handleUpdateButton();
                  }}>{t('Update')}</button>
                </div>
              </div>
            </div>
            <Footer />
          </section>
        </div>
      );
    }
};
export default Update;