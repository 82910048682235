import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/showscore.css";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const ShowScore = () => {
  const { t } = useTranslation();

  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

useEffect(()=>{
  handleChangeLang();
},[]);

  //to send on any other page
  const navigate=useNavigate();

  //hooks to hold data
  const[user,setUser]=useState({});
  const[userScore,setUserScore]=useState(0);
  const[userTotalScore,setUserTotalScore]=useState(0);
  // const[game,setGame]=useState({});

  useEffect(()=>{
    getToken();
    getLocalStorageData();
  },[]);

  //Method to get data from localStorage
  const getLocalStorageData=()=>{
    let user=JSON.parse(localStorage.getItem("user"));
    let userScore=localStorage.getItem("userScore");
    let userTotalScore=localStorage.getItem("userTotalScore");
    // let game=JSON.parse(localStorage.getItem("game"));

    //console.log("user is ",user);
    //console.log("userScore is ",userScore);
    //console.log("userTotalScore is ",userTotalScore);
    // //console.log("game is ",game);

    setUser(user);
    setUserScore(userScore);
    setUserTotalScore(userTotalScore);
    // setGame(game);
  }

  const handleViewAnswers=()=>{
    navigate("/show");
  }

  const handlePlayAgain=()=>{
    navigate("/play");
  }

//Method to Get & Check Token
const getToken=()=>{
  token=localStorage.getItem("token");
}

  if(token===undefined||token===null||token===''||token===' ')
  {
  return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <div>
        <section className="showscore-main">
          <div className="showscore-main-2">
            <div className="rewards-navbar ">
              <div className="rewards-nav-1">
                <button 
                  onClick={()=>{
                    navigate("/home");
                  }}>
                  <span>
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                </button>
                <div className="reward_title">
                  <span className="rewards-para-1">
                    <button><h5>{t("Leaderboard")}</h5></button>
                  </span>
                </div>
              </div>
              <div className="rewards-nav-2">
                <div className="rewards-prfl-img-2">
                  <img alt="ImgSrc" src={user.image} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="showscore-sec-2-cnt prfl-1">
              <div className="showscore-raees">
                {/* <img alt="Img Src" src={user.image} /> */}
                <h2>{userTotalScore}</h2>
                <p className="mb-0">{t('Total Score')}</p>
              </div>
            </div>
            <div className="showscore-sec-1">
              <h5>{t("Scoreboard")}</h5>
              <div className="showscore-scr-data">
                <div className="showscore-scr scr-1">
                  <div className="showscore-scr-name">{t("Correct Answer Point")}</div>
                  <div className="showscore-data-name">18</div>
                </div>
              </div>
              <div className="showscore-scr-data">
                <div className="showscore-scr scr-2">
                  <div className="showscore-scr-name">{t('Current Game Score')}</div>
                  <div className="showscore-data-name">{userScore}</div>
                </div>
              </div>
            </div>
  
            <div className="progress-1">
              <div id="progressbar1">
                <div>
                  <p>{t('Level')} 3</p>
                </div>
              </div>
            </div>
            <div className="showscore-btn">
                <button className="btn btn-orange" onClick={()=>{
                  handlePlayAgain();
                }}>{t('Play Again')}</button>
                <button className="btn btn-info" onClick={()=>{
                  handleViewAnswers();
                }}>{t('VIEW ANSWERS')}</button>
            </div>
  
            {/* <div className="showscore-LAST-1">
              <div className="showscore-back">
                <button onClick={()=>{
                  navigate("/home");
                }}>
                  <span className="showscore-span-1">
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                  <span className="showscore-span-2">{t('Back to Topic')}</span>
                </button>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    );
  }
};
export default ShowScore;