import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import "../Css/EnterCode.css";
import logo from "../Images/Azmoon logo.png";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { matchOtpApi,checkSubscriptionApi } from "../Api/api";
// import PostRequest from "../Api/PostRequest";
import PostRequestNormal from "../Api/PostRequestNormal";
// import { token } from "../Api/api";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: tEn },
    ps: { translation: tPs },
    dr: { translation: tDr },
  },
  // lng:"en",
  // fallbackLng: "en",

  // interpolation: {
  //   escapeValue: false
  // }
});

const EnterCode = () => {
  //To Translate Language
  const { t } = useTranslation();

  const[ani,setAni]=useState('');

  //Method to get user selected language from local storage
  const handleChangeLang = () => {
    let language = localStorage.getItem("language");
    let ani=localStorage.getItem("ani");
    setAni(ani);
    i18n.changeLanguage(language);
  };

  //to load language on page load
  useEffect(() => {
    handleChangeLang();
  }, []);

  //Hook to store loader state
  const[loaderState,setLoaderState]=useState(false);

  //Hook to store visible state
  const[showState,setShowState]=useState(false);

  //Hook to disable button
  const[disable,setDisable]=useState(false);

  //Hook to store resentbutton disable or not
  const[resendDisableState,setResendDisableState]=useState(false);

  //Hook to store resend loader state
  const[resendLoaderState,setResendLoaderState]=useState(false);

  //Hook to store resend text show state
  const[resendShowState,setResendShowState]=useState(false);

  //to go on any other page
  const navigate = useNavigate();

  //Hook to store otp
  const[data,setData]=useState({});

  //Method to handle continue button
  const handleContinueButton=()=>{
    //console.log("data is ",data);

    //checking for empty value
    if(data.otp===undefined)
    {
      //console.log("Empty Input");
      toast.error("Enter OTP First!!");
    }
    else
    {
      //Checking OTP from Backend
      handleProcessing(true);

      let request={"otp":data.otp,"ani":localStorage.getItem('ani')};
      let promise=PostRequestNormal(matchOtpApi,request);
      promise.then(e=>{
        handleResponse(e);
      })
    }

  }

  //handle response
  const handleResponse=(e)=>{
    //console.log("e ",e);
    handleProcessing(false);

    if(e.response==='5')
    {
      navigate("/error");
    }
    else if(e.response==='0')
    {
      //console.log("Token Not Generated");
      toast.error("Token Not Generated");
    }
    else if(e.response==='1')
    {
      //console.log("OTP Validated & Token Generated");
      //console.log("Token is ",e.message);
      localStorage.setItem("token",e.message);

      //Send to Game
      navigate("/home");
    }
    else if(e.response==='2')
    {
      //console.log("Wrong OTP");
      toast.error("Wrong OTP");
    }
    else if(e.response==='3')
    {
      //console.log("OTP Expired");
      toast.error("OTP Expired");
    }
    else if(e.response==='4')
    {
      //console.log("Something Went Wrong");
      toast.error("Something Went Wrong");
    }
    else 
    {
      //console.log("Something Went Wrong");
      toast.error("Something Went Wrong");
    }
  }

  //To show & hide loader etc.
  const handleProcessing=(value)=>{
    setLoaderState(value);
    setShowState(value);
    setDisable(value);
  }

//To show & hide loader etc.
const handleResendProcessing=(value)=>{
  setResendDisableState(value);
  setResendLoaderState(value);
  setResendShowState(value);
}

  //Method to handle resent OTP 
  const handleResendOtp=()=>{
    //console.log("Sent OTP Again");
    handleResendProcessing(true);

    let request={"ani":localStorage.getItem('ani')};
    let promise=PostRequestNormal(checkSubscriptionApi,request);
    promise.then(e=>{
      //console.log("e ",e);
      resentOtpResponse(e);
    })
  }

  const resentOtpResponse=(e)=>{
    handleResendProcessing(false);
    if(e.response==='5')
    {
      navigate("/error");
    }
    else if(e.response==='1')
    {
      toast.success("OTP Sent");
    }
    else 
    {
      toast.error("Something Went Wrong");
    }
  }

  return (
    <div>
      <ToastContainer/>
      <section className="selectlanguage-main">
        <div className="selectlanguage-main-2">
          <div className="entercode-quiz-logo">
            <img alt="Img Src" src={logo} />
          </div>
          {/* <div className="entercode-ques-pic">
            <img alt="Img Src" src={capture} />
          </div> */}
          <div className="entercode-text-1 text-center pt-3">
            <h5 className="mb-1 fw-bold fs-6">
              {t("Enter the verification code")}
            </h5>
            <p className="mb-4">{t("Confirmation code sent to ")}{ani}</p>
          </div>
          <div className="entercode-second-prt">
            <div className="entercode-inputs">
              <input
                type="text"
                id="otp"
                name="otp"
                placeholder={t("Enter OTP")}
                className="entercode-otp-input form-control"
                onChange={(e)=>{
                  setData({...data,otp:e.target.value});
                }}
              />
            </div>
            <div className="entercode-anchors">
              <div className="entercode-ancr-1">
                {/* <a href="/"> */}
                <button
                  className="button-class"
                  disabled={disable}
                  onClick={() => {
                    handleContinueButton();
                  }}
                >
                  <span hidden={showState}>{t("Continue")}</span>
                  <SyncLoader color="white" size="15" className="login-button-loader" loading={loaderState}/>
                </button>
                {/* </a> */}
              </div>
              <div className="entercode-ancr-2 text-center">
                <button disabled={resendDisableState} onClick={() => {handleResendOtp()}}>
                  <span hidden={resendShowState}>{t("Resend the verification code")}</span>
                  <SyncLoader color="black" size="15" className="login-button-loader" loading={resendLoaderState}/>
                </button>
              </div>
            </div>
          </div>
          <div className="login-back-arw">
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              <i className="fa-solid fa-arrow-left-long"></i>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
export default EnterCode;