import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/editprofile.css";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const EditProfile = () => {
  const { t } = useTranslation();

  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

useEffect(()=>{
  handleChangeLang();
},[]);

  //To Navigate on Other Page
  const navigate=useNavigate();

  //To Load getLocalStorageData method
  useEffect(()=>{
    getToken();
    getLocalStorageData();
  },[]);

  //State to hold user data
  const[user,setUser]=useState({});

  //To get Data from Local Storage
  const getLocalStorageData=()=>{
    let user=JSON.parse(localStorage.getItem("user"));
    //console.log(user);
    setUser(user);
  }

  //Handle Update Button
  const handleUpdate=()=>{
    navigate("/update");
  }

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===undefined||token===null||token===''||token===' ')
  {
	return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <>
        <section className="editprofile-main full-height">
          <div className="editprofile-main-2 flex-grow-1">
            <div className="rewards-navbar ">
              <div className="rewards-nav-1">
                <button onClick={()=>{
                    navigate("/profile");
                  }}>
                  <span>
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                </button>
                <div className="reward_title">
                  <span className="rewards-para-1">
                    <button><h5>{t('Edit Profile')}</h5></button>
                  </span>
                </div>
              </div>
            </div>
  
            <div className="editprofile-body-cnt-1">
              <div className="cusprofile_head">
                <div className="editprofile-prfl-img">
                  <img alt="ImgSrc" src={user.image} />
                  <button onClick={()=>{
                      handleUpdate();
                    }}>
                      <i className="fa-solid fa-pencil"></i>
                    </button>
                </div>
                <div className="editprofile-h5-4">
                  <h5 className="mb-0">{user.name}</h5>
                  <p className="mb-0">{user.ani}</p>
                </div>
              </div>
              <div className="editprofile_desc">
                <p>
                  {t('The username should be between 8 and 20 characters long and no underline(_) should be used')}
                </p>
              </div>
              <div className="editprofile-last-btn">
                <button>{t('SAVE CHANGES')}</button>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    );  
  }
};
export default EditProfile;