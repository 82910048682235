import axios from "axios";

const PostRequestNormal=async(url,request)=>{

    let sendResponse;
    
    await axios.post(url,request)
    .then(
        (response)=>{
            // console.log(response.data);
            sendResponse=response.data;
        },
        (error)=>{
            // console.log(error);
            let map={"response":"5"};
            sendResponse=map;
        }
    )
    return sendResponse;
}
export default PostRequestNormal;