import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import reward_image from "../Images/reawards logo.webp";
import gift_image from "../Images/gifts-2.png";
import "../Css/rewards.css";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const Rewards = () => {
  const { t } = useTranslation();

  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

useEffect(()=>{
  handleChangeLang();
},[]);

  //to go on other page
  const navigate = useNavigate();

  //to load on starting
  useEffect(() => {
    getToken();
    getLocalStorageData();
  }, []);

  //state to hold user data
  const [user, setUser] = useState({});

  //Method to get localstorage data
  const getLocalStorageData = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    //console.log("user is ", user);
    setUser(user);
  };

  const handleBack = () => {
    //console.log("clicked on back button");
    navigate("/home");
  };

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===undefined||token===null||token===''||token===' ')
  {
	return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <>
        <section className="rewards-main">
          <div className="rewards-main-2 flex-grow-1">
            <div className="rewards-navbar">
              <div className="rewards-nav-1">
                {" "}
                
                <button onClick={()=>{
                  handleBack();
                }}>
                  <span>
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                </button>
                <div className="reward_title">
                  <span className="rewards-logo-rewards">
                    <img alt="ImgSrc" src={reward_image} />
                  </span>
                  <span className="rewards-para-1">
                    <button onClick={()=>{
                      handleBack();
                    }}><h5>{t('Rewards')}</h5></button>
                  </span>
                </div>
              </div>
  
              <div className="rewards-nav-2">
                {/* <div className="rewards-nm-cnt">
                  <h5>{user.name}</h5>
                  <p>{user.ani}</p>
                </div> */}
                <div className="rewards-prfl-img-2">
                  <img alt="ImgSrc" src={user.image} />
                </div>
              </div>
            </div>
  
            <div className="rewards-upr-body">
              <div className="rewards-upr-body-1">
                <div className="rewards-upr-body-cnt">
                  <h5>{t('All Gift Vouchers')} : 0</h5>
                </div>
                
                <div className="rewards-back-data pt-2">
                  <div className="rewards-received rewards-green">
                    <p>0</p>
                    <h5><i className="fa-solid fa-circle-check"></i> {t('Received')}</h5>
                  </div>
                  <div className="rewards-received rewards-orange">
                    <p>0</p>
                    <h5><i className="fa-solid fa-arrows-rotate"></i> {t('In process')}</h5>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="rewards-body-cnt">
              <h5>{t('Rewards')}</h5>
              <div className="rewards-rewards-img">
                <img alt="ImgSrc" src={gift_image} />
              </div>
              <div className="rewards-last-cnt">
                <p>{t('You have not received a gift yet, join the league now')}</p>
              </div>
              <div className="rewards-leauge-1">
                <button onClick={()=>{
                  navigate("/league");
                }}>{t('League')}</button>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    ); 
  }
};
export default Rewards;