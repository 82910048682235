// const pre=`http://localhost:2976/`;
// const pre=`http://localhost:2929/`;
// const pre=`http://5.189.166.187:5551/AzmoonQuizBackendLive/`;
const pre=`https://www.bigcashdemo.gameit.in/`;
// const pre=`https://rajantest.gamewins.co.za/`;

const sendCategory=`${pre}sendCategories`;
export{sendCategory};

const sendScorers=`${pre}sendScorers`;
export{sendScorers};

const sendUser=`${pre}sendUser`;
export{sendUser};

const sendTopPlayers=`${pre}sendTopPlayers`;
export{sendTopPlayers};

const sendQuestion=`${pre}sendQuestion`;
export{sendQuestion};

const submitScore=`${pre}submitScore`;
export{submitScore};

const sendQuestionLogs=`${pre}sendQuestionLogs`;
export{sendQuestionLogs};

const addToFavourite=`${pre}addFavourite`;
export{addToFavourite};

const deleteFromFavourite=`${pre}removeFavourite`;
export{deleteFromFavourite};

const updateImage=`${pre}addUserImage`;
export{updateImage};

const sendServices=`${pre}sendServices`;
export{sendServices};

const sendsendNotificationsApi=`${pre}sendNotifications`;
export{sendsendNotificationsApi};

const sendLeageApi=`${pre}sendLeague`;
export{sendLeageApi};

const checkSubscriptionApi=`${pre}checkSubscription`;
export{checkSubscriptionApi};

const matchOtpApi=`${pre}matchOtp`;
export{matchOtpApi};

const subscribeUserApi=`${pre}subscribeUser`;
export{subscribeUserApi};

const token="23jDDk@#$JK*ASDI@#$#JKLSF#$#KJKK@#$#$FG@#KKLu983";
export {token};

const unsubRequestApi=`${pre}unsubRequest`;
export{unsubRequestApi};

const saveGamePlaysApi=`${pre}saveGamePlays`;
export{saveGamePlaysApi};